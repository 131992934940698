/* Sass imports */

@use 'reset';
@use 'variables';
@use 'typography';

/* Layout styles */

.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;
}

.bubbles {
  align-items: flex-end;
  display: flex;
  height: 30vh;
  justify-content: flex-end;
  margin-left: 5vw;
  margin-right: 90vw;
  margin-top: 1em;
  width: 5vw;

  img {
    flex: 1;
    max-height: 30vh;
    max-width: 5vw;
  }
}

/* Typography styles */

h1 {
  @extend .font-heading;

  font-size: 5em;
  text-transform: uppercase;
}

h2 {
  @extend .font-subheading;

  font-size: 1.9em;
  padding-bottom: 1.1em;
  padding-top: 0.5em;
}

.userInputLine {
  white-space: nowrap;
}

a i.fas.fa-chevron-down {
  line-height: 0.8em;
  vertical-align: middle;
}

/* Media queries */

// Bootstrap equivalent: medium
@media (min-width: 768px) {
  .container {
    margin-left: 15vw;
    margin-right: 15vw;
    width: 70vw;
  }

  .bubbles {
    height: 30vh;
    margin-left: 8vw;
    margin-right: 85vw;
    margin-top: 1em;
    width: 7vw;
  
    img {
      flex: 1;
      max-height: 30vh;
      max-width: 7vw;
    }
  }

  h1 {
    font-size: 6em;
  }

  h2 {
    font-size: 1.4em;
  }

  .links {
    font-size: 1em;
  }
}

// Bootstrap equivalent: large
@media (min-width: 992px) {
  .container {
    margin-left: 18vw;
    margin-right: 18vw;
    width: 64vw;
  }

  .bubbles {
    height: 30vh;
    margin-left: 9vw;
    margin-right: 82vw;
    margin-top: 1em;
    width: 9vw;
  
    img {
      flex: 1;
      max-height: 30vh;
      max-width: 9vw;
    }
  }

  h1 {
    font-size: 7em;
  }

  h2 {
    font-size: 1.6em;
  }

  .links {
    font-size: 1.2em;
  }
}

// Bootstrap equivalent: extra large
@media (min-width: 1200px) {
  .container {
    margin-left: 21vw;
    margin-right: 21vw;
    width: 58vw;
  }

  .bubbles {
    height: 32vh;
    margin-left: 10vw;
    margin-right: 79vw;
    margin-top: 1em;
    width: 11vw;
  
    img {
      flex: 1;
      max-height: 32vh;
      max-width: 11vw;
    }
  }

  h1 {
    font-size: 7em;
  }

  h2 {
    font-size: 1.6em;
  }

  .links {
    font-size: 1.2em;
  }
}
